import { Reducer } from '@reduxjs/toolkit';
import { OnboardingActionTypes, OnboardingActions } from '../actions/onboarding.actions';

export interface OnboardingState {
  currentStep: number;
  step1: any;
  step2: any;
  step3: any;
  step4: any;
  step5: any;
}

const initialState: OnboardingState = {
  currentStep: 1,
  step1: null,
  step2: null,
  step3: null,
  step4: null,
  step5: null,
};

export const onboardingReducer: Reducer<OnboardingState, any> = (
  state: OnboardingState = initialState,
  action: OnboardingActions
): OnboardingState => {
  switch (action.type) {
    case OnboardingActionTypes.SET_PERSONAL_INFO:
      return {
        ...state,
        step1: action.payload.step1,
        currentStep: action.payload.currentStep,
      };
    case OnboardingActionTypes.SET_EDUCATION_INFO:
      return {
        ...state,
        step2: action.payload.step2,
        currentStep: action.payload.currentStep,
      };
    case OnboardingActionTypes.SET_STEP3_INFO:
      return {
        ...state,
        step3: action.payload.step3,
        currentStep: action.payload.currentStep,
      };
    case OnboardingActionTypes.SET_STEP4_INFO:
      return {
        ...state,
        step4: action.payload.step4,
        currentStep: action.payload.currentStep,
      };
    case OnboardingActionTypes.SET_STEP5_INFO:
      return {
        ...state,
        step5: action.payload.step5,
        currentStep: action.payload.currentStep,
      };
    case OnboardingActionTypes.SET_ONBOARDING_DATA:
      return {
        ...state,
        currentStep: action.payload.currentStep,
        step1: action.payload.step1,
        step2: action.payload.step2,
        step3: action.payload.step3,
        step4: action.payload.step4,
        step5: action.payload.step5,
      };
    default:
      return state;
  }
};
