import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { commonReducer } from './reducers/common.reducer';
import { authReducer } from './reducers/auth.reducer';
import { onboardingReducer } from './reducers/onboarding.reducer';
import { chatReducer } from './reducers/chat.reducer';
import { appliedJobsReducer } from './reducers/applied-jobs.reducer';

const reducer = {
  commonState: commonReducer,
  authState: authReducer,
  onboardingState: onboardingReducer,
  chatState: chatReducer,
  appliedJobsState: appliedJobsReducer
};

const store = configureStore({
  reducer,
  middleware: [thunk],
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
