import { Box, Skeleton } from "@mui/material";

const SkeletonLoader = () => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' minHeight='500px'>
        <Box width={{sm: '100%', md: '400px'}} sx={{ width: 400 }}>
            <Skeleton animation="wave" height={'35px'} />
            <Skeleton animation="wave" height={'35px'} />
            <Skeleton animation="wave" height={'35px'} />
            <Skeleton animation="wave" height={'35px'} />
        </Box>
    </Box>
  );
};

export default SkeletonLoader;