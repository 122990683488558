export enum OnboardingActionTypes {
  SET_PERSONAL_INFO = 'SET_PERSONAL_INFO',
  SET_EDUCATION_INFO = 'SET_EDUCATION_INFO',
  SET_STEP3_INFO = 'SET_STEP3_INFO',
  SET_STEP4_INFO = 'SET_STEP4_INFO',
  SET_STEP5_INFO = 'SET_STEP5_INFO',
  SET_ONBOARDING_DATA = 'SET_ONBOARDING_DATA',
}

interface SetPersonalInfoAction {
  type: OnboardingActionTypes.SET_PERSONAL_INFO;
  payload: any;
}

interface SetEducationInfoAction {
  type: OnboardingActionTypes.SET_EDUCATION_INFO;
  payload: any;
}

interface SetStep3InfoAction {
  type: OnboardingActionTypes.SET_STEP3_INFO;
  payload: any;
}

interface SetStep4InfoAction {
  type: OnboardingActionTypes.SET_STEP4_INFO;
  payload: any;
}

interface SetStep5InfoAction {
  type: OnboardingActionTypes.SET_STEP5_INFO;
  payload: any;
}
interface SetOnboardingDataAction {
  type: OnboardingActionTypes.SET_ONBOARDING_DATA;
  payload: any;
}

export type OnboardingActions =
  | SetPersonalInfoAction
  | SetEducationInfoAction
  | SetStep3InfoAction
  | SetStep4InfoAction
  | SetStep5InfoAction
  | SetOnboardingDataAction;
