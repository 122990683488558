import { SignInResponse, User } from "../../types/auth.interface";

export enum AuthActionTypes {
  SET_USER_INFO = 'SET_USER_INFO',
  SET_UPDATED_USER = 'SET_UPDATED_USER'
}

interface SetUserAction {
  type: AuthActionTypes.SET_USER_INFO;
  payload: SignInResponse;
}

interface SetUpdatedUserAction {
  type: AuthActionTypes.SET_UPDATED_USER;
  payload: User;
}

export type AuthActions = SetUserAction | SetUpdatedUserAction;
