import { Reducer } from '@reduxjs/toolkit';
import { AppliedJobsActionTypes, AppliedJobsActions } from '../actions/applied-jobs.actions';

export interface AppliedJobsState {
  jobs: any;
}

const initialState: AppliedJobsState = {
  jobs: [],
};

export const appliedJobsReducer: Reducer<AppliedJobsState, any> = (
  state: AppliedJobsState = initialState,
  action: AppliedJobsActions
): AppliedJobsState => {
  switch (action.type) {
    case AppliedJobsActionTypes.SET_APPLIED_JOBS_IDS:
      return {
        ...state,
        jobs: action.payload,
      };
    default:
      return state;
  }
};
