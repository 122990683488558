
export enum AppliedJobsActionTypes {
  SET_APPLIED_JOBS_IDS = 'SET_APPLIED_JOBS_IDS',
}

interface SetAppliedJobsIdAction {
  type: AppliedJobsActionTypes.SET_APPLIED_JOBS_IDS;
  payload: {};
}

export type AppliedJobsActions = SetAppliedJobsIdAction;