import { Suspense, useEffect } from 'react';
import './App.scss';
import { ThemeProvider } from '@mui/material';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import { getLightTheme } from './theme';
import React from 'react';
import Toast from './components/common/toast';
import { setAuthToken } from './utils/set-token';
import SkeletonLoader from './components/common/skeleton-loader';

const Auth = React.lazy(() => import('./views/auth'));
const Main = React.lazy(() => import('./views/main'));
const Onboarding = React.lazy(() => import('./views/onboarding'));

function App() {
  const studentInfo = JSON.parse(localStorage.getItem('studentInfo') || '{}');
  useEffect(() => {
    if (studentInfo?.accessToken) {
      setAuthToken(studentInfo.accessToken);
    }
  }, []);
  return (
    <BrowserRouter>
      <ThemeProvider theme={getLightTheme()}>
        <Toast />
        <Suspense fallback={<SkeletonLoader />}>
          <Routes>
            <Route path="/*" element={<Main />} />
            {/* <Route path="/auth/*" element={<Auth />} />
            <Route path="" element={<Navigate replace to="/auth/signin" />} /> */}
            <Route path="/auth/*" element={<Auth />} />
            <Route path="/onboarding/*" element={<Onboarding />} />
            <Route path="" element={<Navigate replace to="/auth/signin" />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
